<template>
    <div>
        Edit Role
    </div>
</template>

<script>
export default {
    name:"editrole",
}
</script>